exports.components = {
  "component---src-templates-article-preview-tsx": () => import("./../../../src/templates/ArticlePreview.tsx" /* webpackChunkName: "component---src-templates-article-preview-tsx" */),
  "component---src-templates-article-tsx": () => import("./../../../src/templates/Article.tsx" /* webpackChunkName: "component---src-templates-article-tsx" */),
  "component---src-templates-articles-tsx": () => import("./../../../src/templates/Articles.tsx" /* webpackChunkName: "component---src-templates-articles-tsx" */),
  "component---src-templates-contact-tsx": () => import("./../../../src/templates/Contact.tsx" /* webpackChunkName: "component---src-templates-contact-tsx" */),
  "component---src-templates-cookie-policy-tsx": () => import("./../../../src/templates/CookiePolicy.tsx" /* webpackChunkName: "component---src-templates-cookie-policy-tsx" */),
  "component---src-templates-cookie-table-tsx": () => import("./../../../src/templates/CookieTable.tsx" /* webpackChunkName: "component---src-templates-cookie-table-tsx" */),
  "component---src-templates-error-tsx": () => import("./../../../src/templates/Error.tsx" /* webpackChunkName: "component---src-templates-error-tsx" */),
  "component---src-templates-home-tsx": () => import("./../../../src/templates/Home.tsx" /* webpackChunkName: "component---src-templates-home-tsx" */),
  "component---src-templates-terms-tsx": () => import("./../../../src/templates/Terms.tsx" /* webpackChunkName: "component---src-templates-terms-tsx" */)
}

